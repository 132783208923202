body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: rgb(245, 98, 77);
    background: linear-gradient(
        120deg,
        rgba(245, 98, 77, 1) 20%,
        rgba(204, 35, 30, 1) 40%,
        rgba(52, 166, 95, 1) 60%,
        rgba(15, 138, 95, 1) 80%,
        rgba(35, 94, 111, 1) 100%
    );
    color: white;
    font-size: larger;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

li {
    list-style-type: none;
}

.center-box {
    margin: 0 auto;
}

.yuuge {
    font-size: 5em;
    margin-bottom: 0em;
}

.pretty-darn-big {
    font-size: 3em;
}

.curly {
    font-family: "Great Vibes", cursive;
}

.fancy {
    font-family: "Mountains of Christmas", cursive;
}

.page-welcome {
    color: antiquewhite;
}

.tree-one {
    width: 200px;
    left: 20px;
    rotate: -15deg;
}

.tree-two {
    width: 200px;
    right: 20px;
    rotate: 15deg;
}

.gimme-my-space-man {
    margin-bottom: 8em;
}

.single-img {
    box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.75);
}

.awesome-box {
    text-transform: uppercase;
    font-size: 4rem;
    font-weight: 700;
    line-height: 1;
    border: 5px solid;
    padding: 2rem;
    /* The important extrinsic width */
    width: min-content;
}
